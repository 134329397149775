import { actions, connect, kea, listeners, path, reducers, selectors } from 'kea'
import { loaders } from 'kea-loaders'
import { api } from 'lib/Api'
import { Scene, sceneLogic } from 'lib/logics/sceneLogic'
import { usageGuardLogic } from 'lib/logics/usageGuardLogic'
import { userLogic } from 'lib/logics/userLogic'

import type { planManagementLogicType } from './planManagementLogicType'

export enum PlanSlug {
  Starter = 'starter',
  Growth = 'growth',
  Pro = 'pro',
}

export interface PlanOffering {
  name: string
  price: number
  slug: PlanSlug
  applies_up_to_qty: number | null
  qty: number
  description: string
  features: {
    apiAccess: boolean
    distCenterLimit: number
    sensorDiscount: number
    shipmentMonthlyLimit: number
    shipmentOveragePrice: number
    support: string
    packagingModelLimit: number
  }
}

export const PURCHASE_OFFERINGS = [
  {
    name: 'Starter',
    price: 99.0,
    slug: PlanSlug.Starter,
    applies_up_to_qty: null,
    qty: 1,
    description: 'For small shippers.',
    features: {
      apiAccess: false,
      distCenterLimit: 1,
      packagingModelLimit: 3,
      sensorDiscount: 0.05,
      shipmentMonthlyLimit: 250,
      shipmentOveragePrice: 0.39,
      support: 'Self service',
    },
  },
  {
    name: 'Growth',
    price: 299.0,
    slug: PlanSlug.Growth,
    applies_up_to_qty: null,
    qty: 1,
    description: 'For shippers with an expanding customer base.',
    features: {
      apiAccess: false,
      distCenterLimit: 3,
      packagingModelLimit: 5,
      sensorDiscount: 0.1,
      shipmentMonthlyLimit: 1000,
      shipmentOveragePrice: 0.29,
      support: 'Email',
    },
  },
  {
    name: 'Pro',
    price: 999.0,
    slug: PlanSlug.Pro,
    applies_up_to_qty: null,
    qty: 1,
    description: 'For established high-volume shippers.',
    features: {
      apiAccess: true,
      distCenterLimit: 7,
      packagingModelLimit: 10,
      sensorDiscount: 0.15,
      shipmentMonthlyLimit: 4000,
      shipmentOveragePrice: 0.25,
      support: 'Full',
    },
  },
] as const

export type CheckoutSessionCallbackParams = {
  stripe_checkout_session_id?: string
  plan?: string
}

export const planManagementLogic = kea<planManagementLogicType>([
  path(['lib', 'components', 'saas-plan-management', 'choosePlanLogic']),
  connect({
    values: [
      userLogic,
      ['organizationBillingProvider', 'organizationPlanTier'],
      usageGuardLogic,
      ['userHasNoChoiceButToChoosePlan'],
      sceneLogic,
      ['scene'],
    ],
    actions: [userLogic, ['loadUser']],
  }),
  actions({
    getCheckoutLink: (offering: PlanOffering) => ({ offering }),
    closeModal: true,
    openModal: true,
  }),
  reducers(() => ({
    userSelectedModalState: [
      false,
      {
        openModal: () => true,
        closeModal: () => false,
      },
    ],
  })),
  loaders(({ values }) => ({
    checkoutLink: [
      null as string | null,
      {
        getCheckoutLink: async ({ offering }) => {
          const billingProvider = values.organizationBillingProvider
          if (!billingProvider) {
            throw new Error('No billing provider')
          }
          const response = await api.get(`/api/billing/plans/checkout/${billingProvider.toLowerCase()}/`, {
            plan: offering.slug,
          })
          return response.link
        },
      },
    ],
  })),
  selectors({
    isPlanManagementModalOpen: [
      (s) => [s.userSelectedModalState, s.scene, s.userHasNoChoiceButToChoosePlan],
      (userSelectedModalState, scene, userHasNoChoiceButToChoosePlan) => {
        const scenesModalNotAllowed = [
          Scene.shopifyIntegrationAuth,
          Scene.shopifyBillingRedirect,
          Scene.login,
          Scene.createAccount,
        ]
        if (scenesModalNotAllowed.includes(scene)) {
          return false
        }
        return userSelectedModalState || userHasNoChoiceButToChoosePlan
      },
    ],
  }),
  listeners(() => ({
    getCheckoutLinkSuccess: ({ checkoutLink }) => {
      if (checkoutLink) {
        window.location.href = checkoutLink
      }
    },
  })),
])
