import { InfoIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Box,
  Button,
  Circle,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spacer,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useActions, useValues } from 'kea'
import { A } from 'kea-router'
import OpenSaasPlanManagementButton from 'lib/components/saas-plan-management/OpenSaasPlanManagementButton'
import { authLogic } from 'lib/logics/authLogic'
import { FeatureFlagName, featureFlagLogic } from 'lib/logics/featureFlagsLogic'
import { Scene, sceneLogic, scenesToURLs } from 'lib/logics/sceneLogic'
import { userLogic } from 'lib/logics/userLogic'
import { IconType } from 'react-icons'
import { BsTruck } from 'react-icons/bs'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'
import { ImCloudUpload, ImLab, ImTable } from 'react-icons/im'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import { TbMicroscope, TbPlugConnected, TbShoppingCart } from 'react-icons/tb'
import { integrationsLogic } from 'scenes/integrations/integrationsLogic'

import { ReactNode } from 'react'

import {
  CarrierSettingsIcon,
  CoolantIcon,
  CopilotIcon,
  DistributionCenterIcon,
  GeneralSettingsIcon,
  KicIconType,
  MaterialsForecastIcon,
  PackagingConfigIcon,
  ProductIcon,
  SensorIcon,
  ShippingMethodIcon,
} from '../Icons/Icons'
import { sideBarLogic } from './sideBarLogic'

export interface NavButtonProps {
  label: string
  scene: Scene
  attentionMessage?: ReactNode
  defaultSegmentParams?: Record<string, string>
  internalOnly?: boolean
  behindFeatureFlag?: FeatureFlagName
}

export interface PrimaryNavButtonProps extends NavButtonProps {
  icon: IconType | KicIconType
  subItems?: NavButtonProps[]
}

function PrimaryNavButton({
  icon,
  scene,
  internalOnly = false,
  defaultSegmentParams = {},
  attentionMessage,
  label,
  behindFeatureFlag,
}: PrimaryNavButtonProps): JSX.Element | null {
  const { featureFlags } = useValues(featureFlagLogic)
  const { scene: currentScene } = useValues(sceneLogic)
  const { sideBarIsOpen } = useValues(sideBarLogic)
  const isSelected = scene === currentScene

  if (internalOnly && !featureFlags['internal-users']) return null
  if (behindFeatureFlag && !featureFlags[behindFeatureFlag]) return null
  const attentionMessageMarkup = attentionMessage ? (
    <Text color="yellow.500" ml={2}>
      {attentionMessage}
    </Text>
  ) : undefined
  return (
    <Link
      color={isSelected ? 'brandBrown.700' : 'brandBrown.50'}
      _hover={{ color: 'brandBrown.700' }}
      as={A}
      href={scenesToURLs[scene](defaultSegmentParams)}
      role="group"
    >
      <Tooltip
        shadow="none"
        label={
          sideBarIsOpen ? (
            attentionMessageMarkup
          ) : (
            <>
              {label}
              {attentionMessage && attentionMessageMarkup}
            </>
          )
        }
        placement="end"
        display="flex"
        alignItems="center"
        ml={2}
        minH={10}
        bg="brandBrown.700"
        borderLeftRadius={0}
        borderRightRadius="full"
        color="brandBrown.50"
        maxW="none"
      >
        <Flex align="center">
          <Box>
            <Circle size="9" bg={isSelected ? 'brandBrown.50' : undefined} _groupHover={{ bg: 'brandBrown.50' }}>
              <Icon boxSize="6" mx={0} as={icon} />
            </Circle>
          </Box>
          {sideBarIsOpen ? (
            <Text color="brandBrown.50" ml={2} fontWeight={isSelected ? 'bold' : 'normal'}>
              {label}
            </Text>
          ) : null}
          {internalOnly && (
            <Icon
              bg="yellow.500"
              color="white"
              as={MdOutlineAdminPanelSettings}
              rounded="full"
              p="2px"
              position="absolute"
              right="2"
            />
          )}
          {attentionMessage && (
            <Circle
              size="2"
              bg="yellow.500"
              position={sideBarIsOpen ? undefined : 'fixed'}
              ml={sideBarIsOpen ? 3 : 10}
            />
          )}
        </Flex>
      </Tooltip>
    </Link>
  )
}

function SideBarDivider({ title }: { title: string }): JSX.Element {
  const { sideBarIsOpen } = useValues(sideBarLogic)
  return (
    <Flex alignItems="center" w="full" h={3} mt={1}>
      <Divider />
      {title && sideBarIsOpen && (
        <>
          <Text textTransform="uppercase" fontSize="sm" color="brandBrown.50" mx={2}>
            {title}
          </Text>
          <Divider />
        </>
      )}
    </Flex>
  )
}

function LoggedInNavButtons(): JSX.Element {
  const { hasOrganization, user, bulkRecommendationEnabled, showSensorBilling } = useValues(userLogic)
  const { scene } = useValues(sceneLogic)
  const { logout } = useActions(authLogic)
  const { sideBarIsOpen } = useValues(sideBarLogic)

  return (
    <Flex
      direction="column"
      w={sideBarIsOpen ? '17rem' : 'full'}
      px={4}
      gap={1}
      h="full"
      align={sideBarIsOpen ? 'start' : 'center'}
    >
      {hasOrganization ? (
        <>
          <SideBarDivider title="Tools" />
          <PrimaryNavButton icon={CopilotIcon} label="Shipping Copilot" scene={Scene.copilot} />
          <PrimaryNavButton icon={BsTruck} label="Shipments" scene={Scene.shipments} />
          <PrimaryNavButton icon={SensorIcon} label="Sensors" scene={Scene.sensors} />
          <PrimaryNavButton icon={TbMicroscope} label="Insights" scene={Scene.insights} />
          <PrimaryNavButton icon={ImCloudUpload} label="Imports" scene={Scene.shipmentImportsList} />
          <PrimaryNavButton
            icon={MaterialsForecastIcon}
            label="Materials Forecasting"
            scene={Scene.materialsForecasting}
            internalOnly
            behindFeatureFlag="materials-forecasting"
          />
          <SideBarDivider title="Settings" />
          <PrimaryNavButton icon={PackagingConfigIcon} label="Packaging" scene={Scene['settings/packaging']} />
          <PrimaryNavButton
            icon={DistributionCenterIcon}
            label="Distribution Centers"
            scene={Scene['settings/distribution-centers']}
          />
          <PrimaryNavButton
            icon={ShippingMethodIcon}
            label="Shipping Methods"
            scene={Scene['settings/shipping-methods']}
          />
          <PrimaryNavButton icon={ProductIcon} label="Products" scene={Scene['settings/products']} />
          <PrimaryNavButton
            icon={GeneralSettingsIcon}
            label="Recommendations"
            scene={Scene['settings/recommendations']}
          />
          <PrimaryNavButton icon={CoolantIcon} label="Coolant Blocks" scene={Scene['settings/coolant-blocks']} />
          <PrimaryNavButton
            icon={(props: any) => (
              <CarrierSettingsIcon
                {...props}
                fill={scene === Scene['settings/carrier-settings'] ? 'brandBrown.700' : 'brandBrown.50'}
                _groupHover={{ fill: 'brandBrown.700' }}
              />
            )}
            label="Carrier Settings"
            scene={Scene['settings/carrier-settings']}
          />
          <IntegrationsNavButton />
          <SideBarDivider title="Advanced" />
          <PrimaryNavButton icon={ImLab} label="Simulation lab" scene={Scene.simulationLab} />
          {bulkRecommendationEnabled && (
            <PrimaryNavButton icon={ImTable} label="Bulk recs." scene={Scene.bulkRecommendationConfigs} />
          )}
        </>
      ) : null}
      <Spacer />
      <Flex direction="column" gap={2} w="full">
        <SideBarDivider title="account" />
        {showSensorBilling && <PrimaryNavButton icon={TbShoppingCart} label="Order Sensors" scene={Scene.buySensors} />}
        <Popover placement="right-end" trigger="hover">
          <PopoverTrigger>
            <Flex alignItems="center" gap={2}>
              <Avatar w="10" h="10" name={user?.first_name || user?.email} />
              {sideBarIsOpen ? <AccountInfo /> : null}
            </Flex>
          </PopoverTrigger>
          <Portal>
            <PopoverContent data-js-focus-visible="none" w="fit-content">
              <PopoverBody>
                <AccountInfo isInPopover />
                <OpenSaasPlanManagementButton />
                <Divider mt="2"></Divider>
                <Button variant="link" colorScheme="red" mt="2" onClick={logout}>
                  Logout
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
        <CollapseControl />
      </Flex>
    </Flex>
  )
}

function CollapseControl(): JSX.Element {
  const { sideBarIsOpen } = useValues(sideBarLogic)
  const { toggleSideBar } = useActions(sideBarLogic)
  return (
    <Button
      _hover={{
        bg: 'brandGreen.600',
        color: 'brandBrown.50',
      }}
      aria-label={sideBarIsOpen ? 'Collapse sidebar' : 'Expand sidebar'}
      onClick={toggleSideBar}
      color="brandBrown.300"
      h={5}
      variant="link"
      leftIcon={sideBarIsOpen ? <FaAngleDoubleLeft /> : <FaAngleDoubleRight />}
      iconSpacing={sideBarIsOpen ? undefined : 0}
      alignSelf="center"
      px={2}
      size="sm"
    >
      {sideBarIsOpen ? 'Collapse sidebar' : null}
    </Button>
  )
}

function AccountInfo({ isInPopover = false }: { isInPopover?: boolean }): JSX.Element {
  const { organizationName, user } = useValues(userLogic)
  return (
    <Box fontSize="sm">
      <Text textTransform="capitalize" overflowWrap="anywhere">
        {user?.email}
      </Text>
      {isInPopover && (
        <Text textTransform="capitalize" mt="0">
          <Text as="span" fontWeight="light">
            Organization:{' '}
          </Text>
          {organizationName ? `${organizationName}` : ''}
        </Text>
      )}
    </Box>
  )
}

function IntegrationsNavButton(): JSX.Element {
  const { integrations } = useValues(integrationsLogic)
  const integrationMessages = Object.values(integrations)
    .filter((integration) => integration.uuid)
    .map((integration) => (integration.is_sync_active ? undefined : `${integration.name} sync not active`))
    .filter(Boolean)
  const attentionMessage = integrationMessages.length > 0 ? integrationMessages.join(' and ') : undefined

  return (
    <PrimaryNavButton
      icon={TbPlugConnected}
      label="Integrations"
      scene={Scene.integrations}
      attentionMessage={attentionMessage}
    />
  )
}

export function SideBar({ children, hideSideBar = false }: { children: ReactNode; hideSideBar: boolean }): JSX.Element {
  const { isAuthenticated } = useValues(authLogic)
  const { scene } = useValues(sceneLogic)
  const { sideBarIsOpen } = useValues(sideBarLogic)
  const { isDemoOrganization } = useValues(userLogic)

  return (
    <Box position="relative" width="100vw" height="100vh" display="flex">
      {!hideSideBar && (
        <Box
          position="relative"
          height="initial"
          backgroundColor="brandBrown.700"
          boxShadow="base"
          borderTopRightRadius="xl"
          borderBottomRightRadius="xl"
        >
          <Flex
            position="sticky"
            top="0"
            height="100vh"
            overflowY="auto"
            pb="5"
            direction="column"
            color="brandBrown.50"
          >
            {isDemoOrganization && sideBarIsOpen ? (
              <HStack borderTopRightRadius="xl" bg="brandGreen.500" alignItems={'center'} justifyContent={'center'}>
                <Heading py="1" textAlign={'center'} size="sm">
                  Demo Account
                </Heading>
                <Tooltip label="This is a demo account and is intended only for evaluation purposes. Synthetic shipments and sensor data will be generated periodically.">
                  <Icon as={InfoIcon} fontSize="xs" />
                </Tooltip>
              </HStack>
            ) : null}
            <Flex justifyContent={'center'} pt={4}>
              {sideBarIsOpen ? (
                <Image src={'/kic-logo.svg'} alt={'Keep it Cool'} height={'5rem'} mb={3} />
              ) : (
                <Image src="/kic-logo-no-text.svg" alt="Keep it Cool" height={6} />
              )}
            </Flex>
            {isAuthenticated ? (
              <LoggedInNavButtons />
            ) : (
              <Box flex="1">
                <Link as={A} ml="10" href={scenesToURLs.login()}>
                  <Text fontWeight={scene === Scene.login ? 'bold' : undefined} fontSize="lg">
                    Login
                  </Text>
                </Link>
                <Link as={A} ml="10" href={scenesToURLs['create-account']()}>
                  <Text fontWeight={scene === Scene.createAccount ? 'bold' : undefined} fontSize="lg">
                    Create account
                  </Text>
                </Link>
              </Box>
            )}
          </Flex>
        </Box>
      )}
      <Box flex="1" overflowX="auto" overflowY="auto">
        {children}
      </Box>
    </Box>
  )
}
